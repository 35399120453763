/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "border-radius",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#border-radius",
    "aria-label": "border radius permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Border Radius"), "\n", React.createElement(_components.p, null, "Utilities for controlling the border radius of an element."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "borderRadius={radius}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "border-radius: {radius};"))))), "\n", React.createElement(_components.h2, {
    id: "rounded-corners",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rounded-corners",
    "aria-label": "rounded corners permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rounded corners"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "borderRadius={radius}"), " utility to apply different border radius sizes to an element."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div display=\"grid\" gap={2} gridTemplateColumns={{ sm: 2, lg: 4 }}>\n      <x.div\n        p={6}\n        bg=\"light-blue-500\"\n        color=\"white\"\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        borderRadius=\"sm\"\n      >\n        sm\n      </x.div>\n      <x.div\n        p={6}\n        bg=\"light-blue-500\"\n        color=\"white\"\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        borderRadius\n      >\n        default\n      </x.div>\n      <x.div\n        p={6}\n        bg=\"light-blue-500\"\n        color=\"white\"\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        borderRadius=\"md\"\n      >\n        md\n      </x.div>\n      <x.div\n        p={6}\n        bg=\"light-blue-500\"\n        color=\"white\"\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        borderRadius=\"lg\"\n      >\n        lg\n      </x.div>\n    </x.div>\n  </template>\n  <x.div borderRadius=\"sm\" />\n  <x.div borderRadius />\n  <x.div borderRadius=\"md\" />\n  <x.div borderRadius=\"lg\" />\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "pills-and-circles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pills-and-circles",
    "aria-label": "pills and circles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pills and circles"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "borderRadius=\"full\""), " utility to create pills and circles."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=indigo"
  }, "<>\n  <template preview>\n    <x.div\n      display=\"flex\"\n      justifyContent=\"center\"\n      alignItems=\"center\"\n      spaceX={6}\n    >\n      <x.div\n        bg=\"indigo-500\"\n        px={6}\n        py={3}\n        color=\"white\"\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        borderRadius=\"full\"\n      >\n        Pill Shape\n      </x.div>\n      <x.div\n        bg=\"indigo-500\"\n        h={24}\n        w={24}\n        color=\"white\"\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        borderRadius=\"full\"\n      >\n        Circle\n      </x.div>\n    </x.div>\n  </template>\n  <x.div borderRadius=\"full\" py={3} px={6}>\n    Pill Shape\n  </x.div>\n  <x.div\n    borderRadius=\"full\"\n    h={24}\n    w={24}\n    display=\"flex\"\n    alignItems=\"center\"\n    justifyContent=\"center\"\n  >\n    Circle\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "no-rounding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#no-rounding",
    "aria-label": "no rounding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "No rounding"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "borderRadius=\"none\""), " to remove an existing border radius from an element."), "\n", React.createElement(_components.p, null, "This is most commonly used to remove a border radius that was applied at a smaller breakpoint."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=rose"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"center\" alignItems=\"center\">\n      <x.div\n        bg=\"rose-500\"\n        p={6}\n        color=\"white\"\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        borderRadius=\"none\"\n      >\n        none\n      </x.div>\n    </x.div>\n  </template>\n  <x.div borderRadius=\"none\">none</x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "rounding-sides-separately",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rounding-sides-separately",
    "aria-label": "rounding sides separately permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rounding sides separately"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "borderRadius"), " shorthands to only round one side an element."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=indigo"
  }, "<>\n  <template preview>\n    <x.div display=\"grid\" gap={2} gridTemplateColumns={{ sm: 2, lg: 4 }}>\n      {['lg lg 0 0', '0 lg lg 0', '0 0 lg lg', 'lg 0 0 lg'].map(\n        (borderRadius) => (\n          <x.div\n            key={borderRadius}\n            p={6}\n            bg=\"indigo-500\"\n            color=\"white\"\n            textAlign=\"center\"\n            fontWeight=\"extrabold\"\n            display=\"flex\"\n            alignItems=\"center\"\n            justifyContent=\"center\"\n            borderRadius={borderRadius}\n          >\n            {borderRadius}\n          </x.div>\n        ),\n      )}\n    </x.div>\n  </template>\n  <x.div borderRadius=\"lg lg 0 0\" />\n  <x.div borderRadius=\"0 lg lg 0\" />\n  <x.div borderRadius=\"0 0 lg lg\" />\n  <x.div borderRadius=\"lg 0 0 lg\" />\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "rounding-corners-separately",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rounding-corners-separately",
    "aria-label": "rounding corners separately permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rounding corners separately"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "borderRadius"), " shorthands to only round one corner an element."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div display=\"grid\" gap={2} gridTemplateColumns={{ sm: 2, lg: 4 }}>\n      {['lg 0 0 0', '0 lg 0 0', '0 0 lg 0', '0 0 0 lg'].map((borderRadius) => (\n        <x.div\n          key={borderRadius}\n          p={6}\n          bg=\"amber-500\"\n          color=\"white\"\n          textAlign=\"center\"\n          fontWeight=\"extrabold\"\n          display=\"flex\"\n          alignItems=\"center\"\n          justifyContent=\"center\"\n          borderRadius={borderRadius}\n        >\n          {borderRadius}\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div borderRadius=\"lg 0 0 0\" />\n  <x.div borderRadius=\"0 lg 0 0\" />\n  <x.div borderRadius=\"0 0 lg 0\" />\n  <x.div borderRadius=\"0 0 0 lg\" />\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the border radius of an element at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "borderRadius={{ md: \"lg\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "borderRadius=\"lg\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div borderRadius={{ md: 'lg' }}>{/* ... */}</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."), "\n", React.createElement(_components.h2, {
    id: "customizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing",
    "aria-label": "customizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing"), "\n", React.createElement(_components.h3, {
    id: "border-radii",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#border-radii",
    "aria-label": "border radii permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Border Radii"), "\n", React.createElement(_components.p, null, "If you'd like to customize your values for all radii, use the ", React.createElement(_components.code, null, "theme.radii"), " section of your theme."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diffjs"
  }, "  // theme.js\n  import { th } from '@xstyled/styled-components'\n\n  export const theme = {\n    radii: {\n      // ...\n-     default: '0.25rem',\n+     default: '5px',\n+     xxl: '30px',\n    },\n  }\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
